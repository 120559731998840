import Vue from 'vue';

export default {    

    printTicketVenta(sale) {

       let itemsProducts = ``;
       let logo_ticket = '';
       if (sale.company_img_url) {
        logo_ticket = `<img class="imgCenter" src='/img/${sale.company_img_url}' height="90px" width="160px" />`;
       }

       let company_phone = '';
       if (sale.company_phone) {
        company_phone = `<span>${sale.company_phone}</span><br>`;
       }

       let type_document = 'FACTURA ELECTRÓNICA';
        if (sale.sales_document === 'BOLETA') {
            type_document = 'BOLETA DE VENTA ELECTRÓNICA';
        } else if (sale.sales_document === 'TICKET') {
            type_document = 'TICKET DE VENTA';
        }

        let customer_address = '';
        if (sale.customer_address) {
          customer_address = sale.customer_address.toUpperCase();
        }

        let paymentMethod = '';
        if (sale.payment_method) {
          paymentMethod = sale.payment_method;
        }

        let payWith = '';
        if (paymentMethod == 'EFECTIVO') {
          if (sale.pay_with > 0) {
            payWith = `
              <tr>
                <td style="width: 70%; text-align: left;">
                    <span class="voucher-label"><b>PAGA CON</b></span>
                </td>
                <td style="width: 30%; text-align: right;">
                    <span class="voucher-label"><b>S/ ${parseFloat(sale.pay_with).toFixed(2)}</b></span>
                </td>
              </tr>
              <tr>
                <td style="width: 70%; text-align: left;">
                    <span class="voucher-label"><b>VUELTO</b></span>
                </td>
                <td style="width: 30%; text-align: right;">
                    <span class="voucher-label"><b>S/ ${parseFloat(sale.change).toFixed(2)}</b></span>
                </td>
              </tr>
            `;
          }
        }

        //let items = [];
        sale.detail.forEach(el => {
            let quantity = el.quantity.toString().padStart(3, '0')
            let name = el.description.trim();
            let value_subtotal = parseFloat(el.subtotal);
            let value_discount = 0;
            if (el.discount_value) {
              value_discount = parseFloat(el.discount_value);
            }            
            let subtotal_item = Math.round((value_subtotal - value_discount) * 100) / 100;
            let subtotal = `S/. ${parseFloat(subtotal_item).toFixed(2)}`.padStart(30, ' ');
            
            itemsProducts += `<tr>
                <td style="text-align: left; vertical-align: top;"><b>${quantity}</b></td>
                <td style="vertical-align: top; word-wrap: break-word !important;">${name}</td>
                <td style="text-align: right; vertical-align: top;">${parseFloat(el.price).toFixed(2)}</td>
                <td style="text-align: right; vertical-align: top;">${subtotal}</td>
            </tr>`;
        });

        //<span><b>HORA EMISION: </b>${Vue.prototype.$moment(sale.sales_date).format('HH:mm:ss a')}</span><br>          
        //<span><b>MONEDA: </b>SOLES</span><br>
        //<span><b>IGV: </b>18.00</span><br>

        let content = `        
          <style type="text/css" media="screen, print">
            @import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');
        
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
            
            .voucher-content{ 
                font-family: 'Open Sans', sans-serif;
                font-size: 10px;
                margin-left: 0px;
                margin-right: 0px;
            }
          </style>
        
        <div class="voucher-content">
        <div style="text-align: center">
             ${logo_ticket}
             <br>
            <br>
            <span><b>${sale.company_name}</b></span><br>
            <span><b>${sale.company_ruc}</b></span><br>
            <span>${sale.company_address}</span><br>
            ${company_phone}
            <br>
            <span><b>${type_document}</b></span><br>
            <span><b>${sale.serie} - ${sale.correlative}</b></span><br><br>
        </div>
        <div>
            <span><b>ADQUIRIENTE</b></span><br>
            <span><b>${sale.customer_type_document}: </b>${sale.customer_document}</span><br>
            <span>${sale.customer_name.toUpperCase()}</span><br>
            <span><b>DIRECCIÓN</b></span><br>
            <span>${customer_address}</span><br>
            <span><b>FECHA EMISION: </b>${Vue.prototype.$moment(sale.sales_date).format('DD/MM/YYYY')}</span><br>            
            <span><b>VENDEDOR: </b>${sale.seller_name.toUpperCase()}</span><br>
            <span><b>FORMA DE PAGO: </b>${paymentMethod}</span>
        </div>
        <div>
            <table style="width: 100%; border-top: 2px solid; border-bottom: 2px solid;font-size: 10px; border-collapse: collapse;">
                <thead>
                    <tr>
                        <th style="text-align: left; width: 10%;">CANT</th>
                        <th style="text-align: left; width: 40%;">DESCRIPCION</th>
                        <th style="text-align: right; width: 25%;">P/U</th>
                        <th style="text-align: right; width: 25%;">TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    ${itemsProducts}
                </tbody>
            </table>
        </div>
        <div>
            <table style="width: 100%;font-size: 10px;border-bottom: 2px solid;">
                <tbody>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>OP. GRAVADAS</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.subtotal).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>OP. GRATUITAS</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.total_gratuitas).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>TOT. DESCUENTO GLOBAL</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.discount).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>I.G.V (18%)</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.igv).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>REDONDEO</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.rounding).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 70%; text-align: left;">
                            <span class="voucher-label"><b>TOTAL VENTA</b></span>
                        </td>
                        <td style="width: 30%; text-align: right;">
                            <span class="voucher-label"><b>S/ ${parseFloat(sale.total_rounded).toFixed(2)}</b></span>
                        </td>
                    </tr>
                    ${payWith}
                    <tr>
                        <td colspan="2">
                            <br>
                            <span><i>SON: ${this.NumerosaLetras(parseFloat(sale.total_rounded).toFixed(2)).toUpperCase()} SOLES</i></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <br>
        <br>
        </div>`;

        return content
    },

    NumerosaLetras (cantidad) {
        var numero = 0;
        cantidad = parseFloat(cantidad);
      
        if (cantidad == "0.00" || cantidad == "0") {
          return "CERO con 00/100 ";
        } else {
          var ent = cantidad.toString().split(".");
          var arreglo = this.separar_split(ent[0]);
          var longitud = arreglo.length;
      
          switch (longitud) {
            case 1:
              numero = this.unidades(arreglo[0]);
              break;
            case 2:
              numero = this.decenas(arreglo[0], arreglo[1]);
              break;
            case 3:
              numero = this.centenas(arreglo[0], arreglo[1], arreglo[2]);
              break;
            case 4:
              numero = this.unidadesdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3]);
              break;
            case 5:
              numero = this.decenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4]);
              break;
            case 6:
              numero = this.centenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5]);
              break;
          }
      
          ent[1] = isNaN(ent[1]) ? '00' : ent[1];

          if (ent[1].length == 1) {
            ent[1] = ent[1] + '0';
          }
          
          return numero.trim() + " con " + ent[1] + "/100";
        }
      },
      
      unidades(unidad) {
        if (unidad == 0) {
          return 'CERO';
        } else {
          let unidades = Array('UN ','DOS ','TRES ' ,'CUATRO ','CINCO ','SEIS ','SIETE ','OCHO ','NUEVE ');
      
          return unidades[unidad - 1];
        }        
      },
      
      decenas(decena, unidad) {
        let diez = Array('ONCE ','DOCE ','TRECE ','CATORCE ','QUINCE' ,'DIECISEIS ','DIECISIETE ','DIECIOCHO ','DIECINUEVE ');
        let decenas = Array('DIEZ ','VEINTE ','TREINTA ','CUARENTA ','CINCUENTA ','SESENTA ','SETENTA ','OCHENTA ','NOVENTA ');
      
        if (decena ==0 && unidad == 0) {
          return "";
        }
      
        if (decena == 0 && unidad > 0) {
          return this.unidades(unidad);
        }
      
        if (decena == 1) {
          if (unidad == 0) {
            return decenas[decena -1];
          } else {
            return diez[unidad -1];
          }
        } else if (decena == 2) {
          if (unidad == 0) {
            return decenas[decena -1];
          }
          else if (unidad == 1) {
            let veinte = '';
            return veinte = "VEINTI" + "UNO";
          }
          else {
            let veinte = '';
            return veinte = "VEINTI" + this.unidades(unidad);
          }
        } else {
      
          if (unidad == 0) {
            return decenas[decena -1] + " ";
          }
          if (unidad == 1) {
            return decenas[decena -1] + " Y " + "UNO";
          }
      
          return decenas[decena -1] + " Y " + this.unidades(unidad);
        }
      },
      
      centenas(centena, decena, unidad) {
        let centenas = Array( "CIENTO ", "DOSCIENTOS ", "TRESCIENTOS ", "CUATROCIENTOS ","QUINIENTOS ","SEISCIENTOS ","SETECIENTOS ", "OCHOCIENTOS ","NOVECIENTOS ");
      
        if (centena == 0 && decena == 0 && unidad == 0) {
          return "";
        }
        if (centena == 1 && decena == 0 && unidad == 0) {
          return "CIEN ";
        }
      
        if (centena == 0 && decena == 0 && unidad > 0) {
          return this.unidades(unidad);
        }
      
        if (decena == 0 && unidad == 0) {
          return centenas[centena - 1]  +  "" ;
        }
      
        if (decena == 0) {
          var numero = centenas[centena - 1] + "" + this.decenas(decena, unidad);
          return numero.replace(" Y ", " ");
        }
        if (centena == 0) {
      
          return  this.decenas(decena, unidad);
        }
      
        return centenas[centena - 1]  +  "" + this.decenas(decena, unidad);
      
      },
      
      unidadesdemillar(unimill, centena, decena, unidad) {
        let numero = this.unidades(unimill) + " MIL " + this.centenas(centena, decena, unidad);
        numero = numero.replace("UN  MIL ", "MIL " );
        if (unidad == 0) {
          return numero.replace(" Y ", " ");
        } else {
          return numero;
        }
      },
      
      decenasdemillar(decemill, unimill, centena, decena, unidad) {
        let numero = this.decenas(decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
        return numero;
      },
      
      centenasdemillar(centenamill,decemill, unimill, centena, decena, unidad) {
      
        let numero = 0;
        numero = this.centenas(centenamill,decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
      
        return numero;
      },
      
      separar_split(texto){
        let contenido = new Array();
        for (var i = 0; i < texto.length; i++) {
          contenido[i] = texto.substr(i,1);
        }
        return contenido;
      }
}